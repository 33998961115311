<template>
  <!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
  <svg
    :class="className"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/duotone/clock.svg -->
    <path
      v-show="iconStyle === 'duotone'"
      d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
      class="fa-secondary"
    />
    <path
      v-show="iconStyle === 'duotone'"
      d="M348.49,321h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"
      class="fa-primary"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheck',
  props: {
    className: {
      type: String,
      required: false,
      default: 'h-4',
    },
    iconStyle: {
      type: String,
      required: false,
      default: 'duotone',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style>
  .fa-secondary{
    opacity:.4
  }
</style>
