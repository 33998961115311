<template>
  <!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
  <svg
    :class="className"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/light/circle.svg -->
    <path
      v-show="dir === 'right' && iconStyle === 'light'"
      d="M441.6 147.2H319.809c5.437-12.189 9.791-27.01 9.791-44.8 0-40.478-32.286-70.4-73.6-70.4-48.546
      0-57.8 53.853-71.683 76.315-11.062 17.761-42.869 57.069-60.919 67.144C117.792 166.194 107.62 160
      96 160H32c-17.673 0-32 14.327-32 32v224c0 17.673 14.327 32 32 32h64c9.167 0 17.428-3.861
      23.262-10.038C153.911 445.719 190.254 480 244.364 480H267.6c59.606 0 90.256-36.084
      88.631-85.87 14.3-17.108 21.279-42.155 16.635-65.494 7.229-11.856 11.348-25.395
      11.885-39.582.013-.351.024-.703.033-1.054H441.6c38.337 0 70.4-31.916 70.4-70.4
      0-38.161-32.77-70.4-70.4-70.4zM32 192h64v224H32V192zm409.6 64h-99.301c14.004
      16.003 15.726 46.718-5.4 66.6 12.247 21.431 1.841 49.771-14.1 58.5 7.116
      42.685-10.993 66.9-55.201 66.9h-23.236c-44.337 0-76.684-31.267-116.364-40.861V208.462c29.835-10.706
      68.904-59.818 83.5-83.262C227.296 99.686 232 64 256 64s41.6 16 41.6 38.4c0 38.4-28.8
      57.9-28.8 76.8h172.8c20.1 0 38.4 18 38.4 38.4 0 20.7-17.7 38.4-38.4 38.4zM84 384c0
      11.046-8.954 20-20 20s-20-8.954-20-20 8.954-20 20-20 20 8.954 20 20z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheck',
  props: {
    className: {
      type: String,
      required: false,
      default: 'h-4',
    },
    iconStyle: {
      type: String,
      required: false,
      default: 'solid',
    },
    dir: {
      type: String,
      required: true,
      default: 'right'
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style>
</style>
