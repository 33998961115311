var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.className,
      attrs: {
        fill: _vm.color,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 448 512",
      },
    },
    [
      _c("path", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dir === "right" && _vm.iconStyle === "light",
            expression: "dir === 'right' && iconStyle === 'light'",
          },
        ],
        attrs: {
          d: "M311.03 131.515l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L387.887\n    239H12c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h375.887l-83.928\n    83.444c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686\n    16.97 0l116.485-116c4.686-4.686 4.686-12.284 0-16.971L328\n    131.515c-4.686-4.687-12.284-4.687-16.97 0z",
        },
      }),
      _c("path", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dir === "left" && _vm.iconStyle === "light",
            expression: "dir === 'left' && iconStyle === 'light'",
          },
        ],
        attrs: {
          d: "M136.97 380.485l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L60.113\n    273H436c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H60.113l83.928-83.444c4.686-4.686\n    4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0l-116.485\n    116c-4.686 4.686-4.686 12.284 0 16.971l116.485 116c4.686 4.686 12.284 4.686\n    16.97-.001z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }