<template>
  <!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
  <svg
    :class="className"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >

    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/duotone/at.svg -->
    <path
      v-show="iconStyle === 'duotone'"
      d="M440 232c0-102.38-83.86-160-184-160-101.46 0-184 82.54-184 184s82.54 184
      184 184a184.46 184.46 0 0 0 99.41-29.13 23.94 23.94 0 0 1 31.37 5.13L397
      428.4a24.94 24.94 0 0 1 1.61 2.21 24 24 0 0 1-7.2 33.17A248.76 248.76 0 0 1
      256 504C118.92 504 8 393.08 8 256S118.94 8 256 8c138 0 248 87.65 248 224 0
      68.32-33.63 133.22-120 145.37v-62c35.72-5.27 56-45.37 56-83.37z"
      class="fa-secondary"
    />
    <path
      v-show="iconStyle === 'duotone'"
      d="M391.79 164.58a24 24 0 0 0-19-28.14 24.26 24.26 0 0 0-4.58-.44h-45a13.52
      13.52 0 0 0-13.43 12v.09c-14.69-17.9-40.45-21.77-60-21.77-74.55 0-137.8
      62.22-137.8 151.45 0 65.3 36.79 105.87 96 105.87 27 0 57.37-15.64 75-38.33
      9.52 34.1 40.61 34.1 70.71 34.1a217.39 217.39 0 0 0
      30.29-2v-62c-.72.1-1.44.22-2.17.3-17.35-.45-16.91-12.85-13.48-30zM234.32
      312.43c-22.25 0-36.07-15.62-36.07-40.77 0-45 30.78-72.73 58.63-72.73 22.29
      0 35.6 15.24 35.6 40.77 0 45.06-33.87 72.73-58.16 72.73z"
      class="fa-primary"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAt',
  props: {
    className: {
      type: String,
      required: false,
      default: 'h-4',
    },
    iconStyle: {
      type: String,
      required: false,
      default: 'duotone',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style>
  .fa-secondary{
    opacity:.4
  }
</style>
