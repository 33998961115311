var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.className,
      attrs: {
        fill: _vm.color,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 448 512",
      },
    },
    [
      _c("path", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconStyle === "solid",
            expression: "iconStyle === 'solid'",
          },
        ],
        attrs: {
          d: "M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255\n    10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24\n    10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2\n    0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24\n    24 0 0 0-7.029-16.97z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }