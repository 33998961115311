<template>
  <!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
  <svg
    :class="className"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/solid/chevron-right.svg -->
    <path
      v-show="dir === 'right'"
      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941
      0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484
      101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373
      24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
    />
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/solid/chevron-left.svg -->
    <path
      v-show="dir === 'left'"
      d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67
      22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38
      9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52
      272.97c-9.37-9.37-9.37-24.57 0-33.94z"
    />
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/solid/chevron-up.svg -->
    <path
      v-show="dir === 'up'"
      d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667
      22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379
      9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569
      0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
    />
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/solid/chevron-down.svg -->
    <path
      v-show="dir === 'down'"
      d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357
      24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317
      33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373
      9.372-24.569 9.372-33.942 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevron',
  props: {
    className: {
      type: String,
      required: false,
      default: 'h-4',
    },
    iconStyle: {
      type: String,
      required: false,
      default: 'solid',
    },
    dir: {
      type: String,
      required: true,
      default: 'left'
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style>
</style>
