<template>
  <!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
  <svg
    :class="className"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <!-- https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/svgs/duotone/check-circle.svg -->
    <path
      v-show="iconStyle === 'duotone'"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm155.31
      195.31l-184 184a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16
      16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16
      16 0 0 1 0 22.62z"
      class="fa-secondary"
    />
    <path
      v-show="iconStyle === 'duotone'"
      d="M227.31 387.31a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16
      16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16
      16 0 0 1 0 22.62l-184 184z"
      class="fa-primary"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheck',
  props: {
    className: {
      type: String,
      required: false,
      default: 'h-4',
    },
    iconStyle: {
      type: String,
      required: false,
      default: 'duotone',
    },
    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style>
  .fa-secondary{
    opacity:.4
  }
</style>
