var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.className,
      attrs: {
        fill: _vm.color,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
      },
    },
    [
      _c("path", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconStyle === "light",
            expression: "iconStyle === 'light'",
          },
        ],
        attrs: {
          d: "M0 448V64h18v384H0zm26.857-.273V64H36v383.727h-9.143zm27.143 0V64h8.857v383.727H54zm44.857\n    0V64h8.857v383.727h-8.857zm36 0V64h17.714v383.727h-17.714zm44.857 0V64h8.857v383.727h-8.857zm18\n    0V64h8.857v383.727h-8.857zm18 0V64h8.857v383.727h-8.857zm35.715 0V64h18v383.727h-18zm44.857\n    0V64h18v383.727h-18zm35.999 0V64h18.001v383.727h-18.001zm36.001 0V64h18.001v383.727h-18.001zm26.857\n    0V64h18v383.727h-18zm45.143 0V64h26.857v383.727h-26.857zm35.714 0V64h9.143v383.727H476zm18\n    .273V64h18v384h-18z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }