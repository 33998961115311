var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      class: _vm.className,
      attrs: {
        fill: _vm.color,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 448 512",
      },
    },
    [
      _c("path", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconStyle === "light",
            expression: "iconStyle === 'light'",
          },
        ],
        attrs: {
          d: "M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244\n    0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083\n    8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957\n    8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12\n    12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373\n    12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673\n    0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z",
        },
      }),
      _c("path", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.iconStyle === "regular",
            expression: "iconStyle === 'regular'",
          },
        ],
        attrs: {
          d: "M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003\n    248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200\n    0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431\n    200-200 200zm0-338c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42\n    18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627\n    5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627\n    0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }